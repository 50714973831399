import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { Navigation } from 'components'
import ProposalLayout from 'layouts/Proposal'

import ConsigOffer from './components/ConsigOffer'
import FGTSOffer from './components/FGTSOffer'
import RefinOffer from './components/RefinOffer'
import useLoanReview from './hooks'
import './loan-review.scss'

const LoanReview = ({ pageContext }) => {
  const { product, setProduct } = useProposal()
  const proposal = product?.proposal

  const { handleSubmit, isLoading } = useLoanReview({ proposal, setProduct })

  const reviewMap = {
    FGTS: <FGTSOffer />,
    fgts_app: <FGTSOffer />,
    consig_refin_inss_ncor: <RefinOffer />,
    consig_ncor: <ConsigOffer />
  }

  return (
    <ProposalLayout pageContext={pageContext}>
      <div className="loan-review">
        <h1 className="loan-review__title">
          Revise as condições do seu empréstimo
        </h1>
        {reviewMap[proposal?.productCode]}
        <Navigation
          submit
          nextStep
          isNextStepButtonLoading={isLoading}
          nextButtonText="Ir para contratação"
          nextButtonId="review-btn"
          onNextStepClick={handleSubmit}
        />
      </div>
    </ProposalLayout>
  )
}

export default LoanReview
