import { useEffect, useState } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'

import {
  webAppCallback,
  goToSimulationProposalCallback
} from 'hooks/useOnContinue'

import { DDPageError } from 'utils/datadog/events'

const useLoanReview = ({ proposal, setProduct }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { changeLeadData } = useLead()

  useEffect(() => {
    changeLeadData({
      previousStep: 'simulation'
    })
  }, [])

  const handleSubmit = async () => {
    try {
      if (proposal?.productCode === 'fgts_app') {
        await webAppCallback({
          proposal,
          setButtonLoading: setIsLoading,
          setProduct
        })
        return
      }

      await goToSimulationProposalCallback({
        proposal,
        setButtonLoading: setIsLoading,
        setProduct
      })
    } catch (err) {
      setIsLoading(false)
      const errorPayload = {
        error: err?.message,
        requestURL: err?.request?.responseURL
      }

      DDPageError({
        name: 'useLoanReview | finishProposal',
        payload: errorPayload
      })
    }
  }

  return {
    handleSubmit,
    isLoading
  }
}

export default useLoanReview
